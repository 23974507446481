export default {
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  // navbar
  buyTBT: '購買 TBT',
  swapUSDT: '兌換 USDT',
  // index
  login: '登入',
  logout: '登出',
  // tbt
  swapUSDTTitle: '兌換 USDT',
  buyTBTTitle: '購買 TBT',
  enterUSDTAmount: '輸入 USDT 數量',
  enterExchangeUSDTAmount: '請輸入欲兌換的 USDT 數量',
  enterTBTAmount: '輸入 TBT 數量',
  enterExchangeTBTAmount: '請輸入欲兌換的 TBT 數量',
  swapToUsdt: '可兌換成 {amount} 顆 USDT ({usdtValue} : 1 兌換)',
  swapToTbt: '可兌換成 {amount} 顆 TBT (1 : {tbtValue} 兌換)',
  buy: '購買',
  sellApprove: '兌換前請先解鎖',
  swap: '兌換',
  // token
  swapToken: '兌換 {token}',
  enterTokenAmount: '輸入 {token} 數量',
  enterExchangeTokenAmount: '請輸入欲兌換的 {token} 數量',
  swapTokenTitle: '兌換 {token}',
  swapToToken: '可兌換成 {amount} 顆 {token} ({tokenValue} : 1 兌換)',
  swapToTbtFromToken: '可兌換成 {amount} 顆 TBT (1 : {tbtValue} 兌換)',
  // other
  yourAddress: '您的收款地址',
  backToIndex: '返回首頁',
  // admin
  addWhiteList: '新增白名單',
  enterWhiteList: '輸入白名單地址',
  enterAddWhiteList: '請輸入欲新增的白名單地址',
  addWhiteListAddress: '新增白名單地址',
  alreadyIsWhiteList: '此地址已在白名單內', 
  deleteWhiteList: '刪除白名單',
  enterDeleteWhiteList: '請輸入欲刪除的白名單地址',
  deleteWhiteListAddress: '刪除白名單地址',
  alreadyNotWhiteList: '此地址不在白名單內', 
  editRate: '調整匯率', 
  enterUSDTRate: '輸入 USDT 換 TBT 匯率', 
  enterTBTRate: '輸入 TBT 換 USDT 匯率', 
  newUSDTRate: '新調整 USDT 換 TBT 匯率', 
  newTBTRate: '新調整 TBT 換 USDT 匯率', 
  add: '確認新增',
  delete: '確認刪除',
  edit: '確認調整',
  confirm: '確認',
  toTBT: '可使用TBT兌換USDT',
  toUSDT: '可使用USDT兌換TBT',
  // token admin
  enterTokenRate: '輸入 {token} 換 TBT 匯率',
  enterTBTToTokenRate: '輸入 TBT 換 {token} 匯率', 
  tokenToTBT: '可使用TBT兌換{token}',
  toToken: '可使用{token}兌換TBT',
  newTokenRate: '新調整 {token} 換 TBT 匯率', 
  newTBTToTokenRate: '新調整 TBT 換 {token} 匯率', 
  // toasted
  loginFirst: '請先登入',
  installMetamask: '請安裝 MetaMask',
  changeMainnet: '請切換到幣安智能鏈',
  changeETH: '請切換至以太主網',
  connectionBreak: 'MetaMask 連接中斷，請重新登入',
  changeWallet: '已切換連接錢包',
  waitApprove: '解鎖中，請稍後',
  waitRegistry: '註冊中，請稍後',
  waitSetting: '設定中，請稍後',
  txSend: '交易已送出',
  userRefuse: '使用者拒絕連線',
  addressHasUsed: '此地址已註冊',
  errorOccured: '發生錯誤',
  USDTUnderBalance: 'USDT 餘額不足',
  underBalance: '餘額不足',
  waitGetData: '正在從鏈上獲取資訊，請稍後',
  cannotGetEthValue: '取得 ETH 市值失敗',
  cannotGetData: '取得資料失敗',
  selectTx: '請選擇要補助之交易',
  txError: '交易發生錯誤',
  agreeFirst: '請勾選同意',
  setProfitRate: '設定抽出的趴數',
  enterProfitRate: '請輸入抽出的趴數',
  newProfitRate: '新調整抽出的趴數', 
  noReward: '尚無收益可領取',
  waitClaim: '提取中，請稍後',
  ast: 'AST 天使分潤',
  holding: '持有量',
  claimable: '可領量',
  claim: '領取',
}