// register the plugin on vue
import ABI from '@/assets/abi/defi.js'
// import store from '../store'
import { DefiAddress, rpcURL } from '@/assets/contract.js'
const Contract = require('web3-eth-contract');
const Web3 = require("web3");
Contract.setProvider(rpcURL);

export default class Defi {
  constructor() {
    this.contract = new Contract(ABI, DefiAddress);
    this.web3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
    // console.log('this.contract', this.contract)
  }

  // Get USDT
  async getRate(type){
    try{
      let result
      if (type === 'tbt'){
        result = await this.contract.methods.tbt_rate().call();
      }else{
        result = await this.contract.methods.usdt_rate().call();
      }
      return parseFloat(result / (10 ** 18)) || 0
    }catch(error){
      console.log('error', error)
      return 'error'
    }
  }

  // swap
  async swap(type, amount){
    amount = amount * 10 ** 18
    const amountString = amount.toLocaleString('fullwide', {useGrouping:false})
    let extraData
    if (type === 'toTbt'){
      extraData = await this.contract.methods.change_usdt(amountString)
    }else{
      extraData = await this.contract.methods.change_tbt(amountString)
    }
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async isWhiteList(type, address){
    let result
    if (type === 'toTbt'){
      result = await this.contract.methods.usdt_white_list(address).call();
    }else{
      result = await this.contract.methods.tbt_white_list(address).call();
    }

    return result
  }
  
  async addWhiteList(type, address){
    let result = await this.isWhiteList(type, address)
    if (result){
      return 'isWhiteList'
    }else{
      let extraData
      if (type === 'toTbt'){
        extraData = await this.contract.methods.set_usdt_white_list(address)
      }else{
        extraData = await this.contract.methods.set_tbt_white_list(address)
      }
      let data = extraData.encodeABI()
      return this.sendTransaction(data)
    }
  }

  async deleteWhiteList(type, address){
    let result = await this.isWhiteList(type, address)
    if (!result){
      return 'isNotWhiteList'
    }else{
      let extraData
      if (type === 'toTbt'){
        extraData = await this.contract.methods.set_usdt_white_list(address)
      }else{
        extraData = await this.contract.methods.set_tbt_white_list(address)
      }
      let data = extraData.encodeABI()
      return this.sendTransaction(data)
    }
  }

  async editRate(type, rate){
    let extraData
    
    rate = rate * 10 ** 18
    const rateString = rate.toLocaleString('fullwide', {useGrouping:false})
    if (type === 'usdt'){
      extraData = await this.contract.methods.set_usdt_rate(rateString)
    }else{
      extraData = await this.contract.methods.set_tbt_rate(rateString)
    }
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async getProfitRate(){
    let result = await this.contract.methods.profit().call();
    return result / (10**18) * 100
  }

  async editProfitRate(rate){
    rate = rate * 10 ** 18
    const rateString = rate.toLocaleString('fullwide', {useGrouping:false})
    let extraData = await this.contract.methods.set_profit(rateString)
    let data = extraData.encodeABI()
    return this.sendTransaction(data)
  }

  async sendTransaction(data){
    const transactionParameters = {
      to: DefiAddress,
      from: window.ethereum.selectedAddress,
      data: data,
    };
    
    try{
      let txHash = await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParameters],
      })
      return {txHash: txHash}
    }catch(error){
      // console.log('error', error)
      return error
    }
  }
}