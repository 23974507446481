// mainnet
export const DefiAddress = '0x353F952fE32f957C16012D4405678dCF71cA04Ae';
export const TBTAddress = '0xd7F97f2cBcDC696484dA7C04AD012723392Ce30B';
export const USDTAddress = '0x55d398326f99059fF775485246999027B3197955';
export const ASTAddress = '0xAEecb524B33D42a7B1f652cB52121929A3BB201d';
export const USDCDefiAddress = '0xc5b9B89233C1DbE11F78a3D70e24Ab94BC4Bf4a7';
export const USDCAddress = '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d';
export const rpcURL = 'https://bsc-dataseed1.binance.org/';

// // testnet
// export const DefiAddress = '0x865c8052b7eccC614F547bEF36067f28cDccda0C';
// export const TBTAddress = '0xfAf5a84a45060E78a1e7Ee6f9830cd67132119a9';
// export const USDTAddress = '0xdFB4Fdc16960179AcB0b3e66B86a49C6172258cd';
// export const rpcURL = 'https://data-seed-prebsc-1-s1.binance.org:8545';