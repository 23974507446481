export default {
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  // navbar
  buyTBT: 'TBT',
  swapUSDT: 'USDT',
  // index
  login: 'LOG IN',
  logout: 'LOG OUT',
  // tbt
  swapUSDTTitle: 'SWAP FOR USDT',
  buyTBTTitle: 'BUY TBT',
  enterUSDTAmount: 'Enter an amount, USDT',
  enterExchangeUSDTAmount: 'Enter the Desired USDT Quantity',
  enterTBTAmount: 'Enter an amount, TBT',
  enterExchangeTBTAmount: 'Enter the Desired TBT Quantity',
  swapToUsdt: 'Equivalent to {amount} USDT ({usdtValue} TBT = 1 USDT)',
  swapToTbt: 'Equivalent to {amount} TBT (1 USDT = {tbtValue} TBT)',
  buy: 'BUY',
  sellApprove: 'Unlock before Swapping',
  swap: 'SWAP',
  // token
  swapToken: '{token}',
  enterTokenAmount: 'Enter an amount, {token}',
  enterExchangeTokenAmount: 'Enter the Desired {token} Quantity',
  swapTokenTitle: 'SWAP FOR {token}',
  swapToToken: 'Equivalent to {amount} {token} ({tokenValue} TBT = 1 {token})',
  swapToTbtFromToken: 'Equivalent to {amount} TBT (1 {token} = {tbtValue} TBT)',
  // other
  yourAddress: 'Your Wallet Address',
  backToIndex: 'HOMEPAGE',
  // admin
  addWhiteList: '新增白名單',
  enterWhiteList: '輸入白名單地址',
  enterAddWhiteList: '請輸入欲新增的白名單地址',
  addWhiteListAddress: '新增白名單地址',
  alreadyIsWhiteList: '此地址已在白名單內', 
  deleteWhiteList: '刪除白名單',
  enterDeleteWhiteList: '請輸入欲刪除的白名單地址',
  deleteWhiteListAddress: '刪除白名單地址',
  alreadyNotWhiteList: '此地址不在白名單內', 
  editRate: '調整匯率', 
  enterUSDTRate: '輸入 USDT 換 TBT 匯率', 
  enterTBTRate: '輸入 TBT 換 USDT 匯率', 
  newUSDTRate: '新調整 USDT 換 TBT 匯率', 
  newTBTRate: '新調整 TBT 換 USDT 匯率', 
  add: '確認新增',
  delete: '確認刪除',
  edit: '確認調整',
  confirm: '確認',
  toTBT: '可使用TBT兌換USDT',
  toUSDT: '可使用USDT兌換TBT',
  // token admin
  enterTokenRate: '輸入 {token} 換 TBT 匯率',
  enterTBTToTokenRate: '輸入 TBT 換 {token} 匯率', 
  tokenToTBT: '可使用TBT兌換{token}',
  toToken: '可使用{token}兌換TBT',
  newTokenRate: '新調整 {token} 換 TBT 匯率', 
  newTBTToTokenRate: '新調整 TBT 換 {token} 匯率', 
  // toasted
  loginFirst: 'Please log in first',
  installMetamask: 'Please download MetaMask first',
  changeMainnet: 'Please switch to Binance Smart Chain(BSC)',
  changeETH: 'Please switch to Ethereum Mainnet',
  connectionBreak: 'Disconnected. Please log in again',
  changeWallet: 'Successfully changed the connected account',
  waitApprove: 'Unlocking, Please wait',
  waitRegistry: 'Registering, Please wait',
  waitSetting: 'Setting, Please wait',
  txSend: 'Transaction Sent',
  userRefuse: 'The user refused to connect',
  addressHasUsed: 'The address has been registered',
  errorOccured: 'Error',
  USDTUnderBalance: 'USDT balance is insufficient',
  underBalance: 'Insufficient Balance',
  waitGetData: 'Please wait, we are loading data from blockchain',
  cannotGetEthValue: 'data failed: error occured in collecting the market value of ETH',
  cannotGetData: 'data failed: error occured in collecting data',
  selectTx: 'Please choose the transaction you need to subsidize',
  txError: 'trade failed: error occured while trading',
  agreeFirst: 'Please agree to the terms',
  setProfitRate: '設定抽出的趴數',
  enterProfitRate: '請輸入抽出的趴數',
  newProfitRate: '新調整抽出的趴數', 
  noReward: 'Currently No Return',
  waitClaim: 'Withdrawing, Please wait',
  ast: 'AST',
  holding: 'The Quantity You Hold',
  claimable: 'The Quantity You Get',
  claim: 'Get',
}