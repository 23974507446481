<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dense
    >
      <div class="d-flex align-center">
        <img
          class="can-click"
          alt="tbt-logo"
          src="@/assets/img/icon-tbt-blur.png"
          width="40"
          @click="()=>{if ($route.name!=='HomeUsdc'){ $router.push({name: 'HomeUsdc'})}}"
        >
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex">
        <btn class="ml-3" color="white" :buttonText="$store.state.account? `${shortAddress} ${$t('logout')}` : `${$t('login')}`" @clickBtn="log()"></btn>
        <v-menu
          open-on-hover
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-3"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <img src="@/assets/img/translate.png" alt="" height="20px">
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-for="(item, index) in $store.state.langs"
              :key="index"
              class="can-click"
              @click="changeLang(item)"
            >
              <v-list-item-title>{{ $t(item) }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>

    <v-main id="main">
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import btn from '@/components/btn.vue';
export default {
  name: 'App',
  data: () => ({}),
  components:{
    btn
  },
  computed:{
    shortAddress(){
      return `${this.$store.state.account.slice(0, 6)}...${this.$store.state.account.slice(38)}`
    }
  },
  methods:{
    async log(){
      if (this.$store.state.account){
        this.$store.commit('clearInfo')
        this.$cookies.remove('address')
        this.$router.push({name: 'HomeUsdc'})
      }else{
        if (window.ethereum) {
          await this.connectMetamask()
        } else {
          window.addEventListener('ethereum#initialized', this.connectMetamask, {
            once: true,
          });

          // If the event is not dispatched by the end of the timeout,
          // the user probably doesn't have MetaMask installed.
          setTimeout(this.connectMetamask, 2000); // 3 seconds
        }
        this.$forceUpdate()
      }
    },
    clickNavBtn(e){
      if (!this.$store.state.account && this.$route.path !== '/calculator'){
        e.preventDefault()
        this.$toasted.error(this.$t('loginFirst'))
      }
    },
    changeLang(lang){
      this.$store.commit('updateLang', lang)
      this.$i18n.locale = lang
      this.$router.push({ name: this.$route.name, params: {lang: lang} });
    },
    async connectMetamask() {
      let _this = this
      if (window.ethereum){
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(_this.handleAccountsChanged)
          .catch((err) => {
            if (err.code === 4001) {
              // EIP-1193 userRejectedRequest error
              // If this happens, the user rejected the connection request.
              this.$toasted.error('Please connect to MetaMask.');
            } else {
              console.error(err);
            }
          });
      }else{
        this.$toasted.error(this.$t('installMetamask'))
      }
    },
    async handleAccountsChanged(accounts){
      if (accounts.length === 0) {
        // MetaMask is locked or the user has not connected any accounts
        this.$toasted.error('Please connect to MetaMask.');
      } else if (accounts[0] !== this.$store.state.account) {
        this.$store.commit('updateAccount', accounts[0]);
        this.$cookies.set('address', accounts[0]);
        window.location.reload()
      }
    },
    checkChainId(chainId){
      let isEthereum = chainId === '0x1' || chainId === 1
      // let isEthereum = chainId === '0x4' || chainId === 4

      let isBsc = chainId === '0x38' || chainId === 56
      // let isBsc = chainId === '0x61' || chainId === 97
      this.$store.commit('updateChainId', isBsc);
      this.$store.commit('updateIsEth', isEthereum);
      if (!isBsc){
        if (this.$route.name !== 'Ethegt-send'){
          this.$toasted.error(this.$t('changeMainnet'));
        }
      }
    }
  },
  async mounted(){
    // console.log('==========default==========')
    let _this = this

    if (window.ethereum){
      // metamask disconnect
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length === 0){
          this.$cookies.remove('address')
          this.$store.commit('clearInfo')
          this.$toasted.error(_this.$t('connectionBreak'))
        }else{
          this.$store.commit('updateAccount', accounts[0]);
          this.$cookies.set('address', accounts[0]);
          this.$toasted.show(_this.$t('changeWallet'));
          window.location.reload()
        }
      });

      // get chain id
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      this.checkChainId(chainId)
      window.ethereum.on('chainChanged', _this.checkChainId);

      if (!window.ethereum.selectedAddress){
        let address = this.$cookies.get('address')
        if (address){
          this.$cookies.remove('address')
        }
      }
    }

    let width = document.body.clientWidth
    this.$store.commit('updateWidth', width)
    window.onresize = () => {
      return (() => {
        let width = document.body.clientWidth
        this.$store.commit('updateWidth', width)
      })()
    }
  }
};
</script>

<style lang="scss">
// layout
#main{
  background: url('../src/assets/img/background.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
img[alt="tbt-logo"]{
  // box-shadow: 0 0 4px 1px rgba(255, 255, 255);
  // filter: drop-shadow(#FFFFFF 0 0 1px);
  // webkit-filter: drop-shadow(#FFFFFF 0 0 1px);
}
.card-wrap{
  padding: 40px 60px;
  box-shadow: 0 4px 16px 4px rgb(0 0 0 / 20%) !important;
  @media (max-width: 600px){
    padding: 40px 20px;
  }
}
.w-full{
  width: 100%;
}
.h-full{
  height: 100%;
}
// common style
.text-shadow{
  text-shadow: 0 2px 5px #333;
}
.gas-text{
  font-size: 24px;
}
.can-click{
  cursor: pointer;
}
.toasted.toasted-primary.error{
  background-color: #E53935 !important;
}
.toasted.toasted-primary.default{
  background-color: #0abbb5 !important;
}
</style>