import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import app from '../main'
import Home from '../views/Home.vue'
const cookies = require('vue-cookies')
Vue.use(VueRouter)
Vue.use(cookies);

const routes = [
  {
    path: '/:lang',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: false },
  },

  // TBT -> USDT
  {
    path: '/:lang/usdt',
    name: 'TBT',
    component: () => import('../views/Tbt/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/usdt/swap',
    name: 'TBT-swap',
    component: () => import('../views/Tbt/swap.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/usdt/ast',
    name: 'TBT-ast',
    component: () => import('../views/Tbt/ast.vue'),
    meta: { requiresAuth: true },
  },
  // // USDC home
  // {
  //   path: '/:lang',
  //   name: 'HomeUsdc',
  //   component: () => import('../views/HomeUsdc.vue'),
  //   meta: { requiresAuth: false },
  // },
  // TBT -> USDC
  {
    path: '/:lang/tbt',
    name: 'Usdc',
    component: () => import('../views/Usdc/index.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/tbt/swap',
    name: 'Usdc-swap',
    component: () => import('../views/Usdc/swap.vue'),
    meta: { requiresAuth: true },
  },

  // admin
  {
    path: '/:lang/admin',
    name: 'USDCAdmin',
    component: () => import('../views/Admin/indexUsdc.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/:lang/usdt/admin',
    name: 'Admin',
    component: () => import('../views/Admin/index.vue'),
    meta: { requiresAuth: true },
  },

  {
    // will match everything
    path: '*',
    component: () => import('../views/Other.vue'),
    meta: { requiresAuth: false },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  // console.log('to.params.lang', to.params.lang)
  if (!to.params.lang || !store.state.langs.includes(to.params.lang)){
    next('/en')
  }

  // Get user info from cookies
  try{
    let address = Vue.$cookies.get('address')
    if (address != undefined) {
      if ( store.state.account === '' ){
        try{
          store.commit('updateAccount', address)
        }catch(error){
          console.log('error', error)
        }
      }
    }
  }catch(error){
    console.log('error', error)
    next();
  }

  // 如果 router 轉跳的頁面需要驗證 requiresAuth: true
  if (to.matched.some(record => {
    // console.log(record.name, record.meta.requiresAuth);
    return record.meta.requiresAuth;
  })) {
    // 未登入
    if (store.state.account === ''){
      try{
        next({ name: 'HomeUsdc' });
      }catch(error){
        console.log('error', error)
      }
    }else{
      next(); // 往下繼續執行
    }
  } else {
    next(); // 往下繼續執行
  }
});

router.afterEach((to) => {
  // Get lang from route path
  if (to.params.lang){
    store.commit('updateLang', to.params.lang)
    if (app){
      app.$i18n.locale = to.params.lang
    }
  }
});


export default router
