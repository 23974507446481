import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(Vuex)
Vue.use(VueAxios, axios);

export default new Vuex.Store({
  state: {
    nowWidth: 0,
    account: '',
    chainId: false,
    isEth: false,
    tokenList: [],
    locale: 'en',
    langs: ['en', 'cn']
  },
  mutations: {
    updateWidth(state, width){
      state.nowWidth = width
    },
    updateAccount(state, account){
      state.account = account
    },
    updateChainId(state, chainId){
      state.chainId = chainId
    },
    updateIsEth(state, isEth){
      state.isEth = isEth
    },
    updateTokenList(state, tokenList){
      state.tokenList = tokenList
      let EGT = tokenList.find((item)=>item.name === 'egt')
      if (EGT){
        state.EGTAddress = EGT.tokenaddress
      }
      let ETH = tokenList.find((item)=>item.name === 'eth')
      if (ETH){
        state.ETHAddress = ETH.tokenaddress
      }
    },
    updateLang(state, lang){
      state.locale = lang
    },
    clearInfo(state){
      state.account = ''
    },
  },
  actions: {
    
  },
  modules: {
  }
})
