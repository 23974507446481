<template>
  <div class="btn" :class="{'d-flex justify-center':isCenter}" :style="{width: width? width:''}">
    <v-btn
      class="btn-text"
      :color="color"
      :width="width"
      :height="height"
      :block="isBlock"
      :disabled="isDisabled"
      :dark="isDark"
      depressed
      @click.stop="clickBtn()"
      :outlined="isOutlined"
    >
      {{ $t(buttonText) }}
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    buttonText: String,
    width: [Number, String],
    height: Number,
    isBlock: {
      type: Boolean,
      default: false,
    },
    isOutlined: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'primary'
    },
    isCenter:{
      type: Boolean,
      default: false
    },
    isDark:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickBtn() {
      this.$emit("clickBtn");
    },
  },
};
</script>