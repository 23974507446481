<template>
  <div class="index" style="padding: 20px 0;">
    <v-row justify="center" align="center">
      <v-col cols="11" sm="9" md="6">
        <h2 class="white--text text-center mb-5">TBT - USDT</h2>
        <v-row justify="space-between">
          <v-col cols="6" md="5" v-for="(item, i) in items" :key="i">
            <v-card
              class="index-card d-flex flex-column align-center can-click pa-8"
              light
              color="rgba(255, 255, 255, .8)"
              @click="clickCard(item.link)"
            >
              <img class="mb-5" :src="item.img" :width="$store.state.nowWidth>425?'100px':'80px'">
              <div class="text-center">{{ $t(item.text) }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <hr class="line my-10">

    <v-row justify="center" align="center">
      <v-col cols="11" sm="9" md="6">
        <h2 class="white--text text-center mb-5">TBT - USDC</h2>
        <v-row justify="space-between">
          <v-col cols="6" md="5" v-for="(item, i) in usdcItem" :key="i">
            <v-card
              class="index-card d-flex flex-column align-center can-click pa-8"
              light
              color="rgba(255, 255, 255, .8)"
              @click="clickCard(item.link)"
            >
              <img class="mb-5" :src="item.img" :width="$store.state.nowWidth>425?'100px':'80px'">
              <div class="text-center">{{ $t(item.text, {token: 'USDC'}) }}</div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Home",
  data(){
    return {
      items: [
        {
          text: 'buyTBT',
          img: require('@/assets/img/icon-tbt.png'),
          link: 'TBT'
        },
        {
          text: 'swapUSDT',
          img: require('@/assets/img/icon-usdt.png'),
          link: 'TBT-swap'
        },
      ],
      usdcItem: [
        {
          text: 'buyTBT',
          img: require('@/assets/img/icon-tbt.png'),
          link: 'Usdc'
        },
        {
          text: 'swapToken',
          img: require('@/assets/img/icon-usdc.png'),
          link: 'Usdc-swap'
        }
      ]
    }
  },
  methods:{
    clickCard(link){
      if (this.$store.state.account){
        this.$router.push({name: link})
      }else{
        this.$toasted.error(this.$t('loginFirst'))
      }
    }
  },
  async mounted(){
    
  }
}
</script>
<style lang="scss">
.index{
  min-height: fit-content;
  // height: 100vh;
  .index-card{
    // padding: 40px 60px;
    box-shadow: 0 4px 16px 4px rgb(0 0 0 / 20%) !important;
    // @media (max-width: 425px){
    //   padding: 30px 20px;
    // }
  }
  .line {
    border-top: none;
    border-left: none;
    border-right: none;
    
  }
}
</style>