export default {
  // langs
  tw: '繁體中文',
  jp: '日本語',
  en: 'English',
  cn: '简体中文',
  // navbar
  buyTBT: '购买 TBT',
  swapUSDT: '兑换 USDT',
  // index
  login: '登入',
  logout: '登出',
  // tbt
  swapUSDTTitle: '兑换 USDT',
  buyTBTTitle: '购买 TBT',
  enterUSDTAmount: '输入 USDT 数量',
  enterExchangeUSDTAmount: '请输入欲兑换的 USDT 数量',
  enterTBTAmount: '输入 TBT 数量',
  enterExchangeTBTAmount: '请输入欲兑换的 TBT 数量',
  swapToUsdt: '可兑换成 {amount} 颗 USDT ({usdtValue} : 1 兑换)',
  swapToTbt: '可兑换成 {amount} 颗 TBT (1 : {tbtValue} 兑换)',
  buy: '购买',
  sellApprove: '兑换前请先解锁',
  swap: '兑换',
  // token
  swapToken: '兑换 {token}',
  enterTokenAmount: '输入 {token} 数量',
  enterExchangeTokenAmount: '请输入欲兑换的 {token} 数量',
  swapTokenTitle: '兑换 {token}',
  swapToToken: '可兑换成 {amount} 颗 {token} ({tokenValue} : 1 兑换)',
  swapToTbtFromToken: '可兑换成 {amount} 颗 TBT (1 : {tbtValue} 兑换)',
  // other
  yourAddress: '您的收款地址',
  backToIndex: '返回首页',
  // admin
  addWhiteList: '新增白名单',
  enterWhiteList: '输入白名单地址',
  enterAddWhiteList: '请输入欲新增的白名单地址',
  addWhiteListAddress: '新增白名单地址',
  alreadyIsWhiteList: '此地址已在白名单内', 
  deleteWhiteList: '删除白名单',
  enterDeleteWhiteList: '请输入欲删除的白名单地址',
  deleteWhiteListAddress: '删除白名单地址',
  alreadyNotWhiteList: '此地址不在白名单内', 
  editRate: '调整汇率', 
  enterUSDTRate: '输入 USDT 换 TBT 汇率', 
  enterTBTRate: '输入 TBT 换 USDT 汇率', 
  newUSDTRate: '新调整 USDT 换 TBT 汇率', 
  newTBTRate: '新调整 TBT 换 USDT 汇率', 
  add: '确认新增',
  delete: '确认删除',
  edit: '确认调整',
  confirm: '确认',
  toTBT: '可使用TBT兑换USDT',
  toUSDT: '可使用USDT兑换TBT',
  // token admin
  enterTokenRate: '输入 {token} 换 TBT 汇率',
  enterTBTToTokenRate: '输入 TBT 换 {token} 汇率', 
  tokenToTBT: '可使用TBT兑换{token}',
  toToken: '可使用{token}兑换TBT',
  newTokenRate: '新调整 {token} 换 TBT 汇率', 
  newTBTToTokenRate: '新调整 TBT 换 {token} 汇率',
  // toasted
  loginFirst: '请先登入',
  installMetamask: '请安装 MetaMask',
  changeMainnet: '请切换到币安智能链',
  changeETH: '请切换至以太主网',
  connectionBreak: 'MetaMask 连接中断，请重新登入',
  changeWallet: '已切换连接钱包',
  waitApprove: '解锁中，请稍后',
  waitRegistry: '注册中，请稍后',
  waitSetting: '设定中，请稍后',
  txSend: '交易已送出',
  userRefuse: '使用者拒绝连线',
  addressHasUsed: '此地址已注册',
  errorOccured: '发生错误',
  USDTUnderBalance: 'USDT 余额不足',
  underBalance: '余额不足',
  waitGetData: '正在从链上获取资讯，请稍后',
  cannotGetEthValue: '取得 ETH 市值失败',
  cannotGetData: '取得资料失败',
  selectTx: '请选择要补助之交易',
  txError: '交易发生错误',
  agreeFirst: '请勾选同意',
  setProfitRate: '设定抽出的趴数',
  enterProfitRate: '请输入抽出的趴数',
  newProfitRate: '新调整抽出的趴数', 
  noReward: '尚无收益可领取',
  waitClaim: '提取中，请稍后',
  ast: 'AST 天使分润',
  holding: '持有量',
  claimable: '可领量',
  claim: '领取',
}